import axios from 'axios'

const baseURL = process.env.VUE_APP_API_BASE_URL;

const getToken = () => localStorage.getItem("token");

const getAuthorizationHeader = () => `Bearer ${getToken()}`;

export const HTTP = axios.create({
  baseURL,
  headers: { Authorization: getAuthorizationHeader() },
});